import { SerializedPushSubscription } from '../../types/Input'

const arrayBufferToBase64 = (buffer: ArrayBuffer): string => {
  let binary = ''
  const bytes = new Uint8Array(buffer)
  bytes.forEach((b) => (binary += String.fromCharCode(b)))
  return btoa(binary)
}

export const serializePushSubscription = async (
  subscription: PushSubscription
): Promise<SerializedPushSubscription> => {
  const p256dh = await subscription.getKey('p256dh')
  const auth = await subscription.getKey('auth')

  return {
    endpoint: subscription.endpoint,
    keys: {
      p256dh: arrayBufferToBase64(p256dh),
      auth: arrayBufferToBase64(auth),
    },
  }
}
