import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { format } from 'date-fns'
import { TFunction } from 'i18next'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'

import { useTypedDispatch } from '../../'
import {
  deleteDeviceAction,
  sendTestPushMessageAction,
  subscribePushNotificationsAction,
  unsubscribePushNotificationsAction,
  updateDeviceNameAction,
} from '../../actions/deviceManagement'
import { Device, PushStatus, TestPushMessage } from '../../types/Input'
import Button from '../button/Button'
import ErrorText from '../ErrorText'
import TestMessages from './TestMessages'

const DetailsContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
  borderTop: 'none',
}))

const DeviceNameEditor = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  '& input': {
    flex: 1,
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.divider}`,
    marginRight: theme.spacing(1),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

const ActionLink = styled('button')(({ theme }) => ({
  background: 'none',
  border: 'none',
  color: theme.palette.primary.main,
  textDecoration: 'underline',
  cursor: 'pointer',
  padding: 0,
  ...theme.typography.body2,
  marginRight: theme.spacing(1),
}))

const getPushStatusMessage = (pushStatus: PushStatus, t: TFunction) => {
  switch (pushStatus) {
    case 'not_possible':
      return t('deviceManagement.pushStatus.notPossible')
    case 'permission_granted':
      return t('deviceManagement.pushStatus.permissionGranted')
    case 'not_allowed_in_browser':
      return t('deviceManagement.pushStatus.notAllowedInBrowser')
    case 'blocked_by_user':
      return t('deviceManagement.pushStatus.blocked')
    case 'subscription_missing':
      return t('deviceManagement.pushStatus.blocked')
    default:
      return ''
  }
}

const isAllowPushPossibleForPushStatus = (status: PushStatus) => {
  return ['blocked_by_user', 'not_allowed_in_browser', 'subscription_missing'].includes(status)
}

type DeviceDetailsProps = {
  device: Device
  isCurrentDevice: boolean
  canBlockMessages: boolean
}

const DeviceDetails = ({ device, isCurrentDevice, canBlockMessages }: DeviceDetailsProps) => {
  const { t } = useTranslation()
  const dispatch = useTypedDispatch()
  const [newDeviceName, setNewDeviceName] = useState<string>(device.deviceName)
  const [error, setError] = useState<string | null>(null)

  const pushStatusMessage = getPushStatusMessage(device.pushStatus, t)

  const handleSaveName = async () => {
    try {
      await dispatch(updateDeviceNameAction({ device, newName: newDeviceName }))
    } catch (err) {
      setError(err as string)
    }
  }

  const handleSendTestMessage = async () => {
    const testMessage: TestPushMessage = {
      messageId: uuidv4(),
      sentAt: new Date().toISOString(),
      receivedAt: undefined,
    }

    try {
      await dispatch(
        sendTestPushMessageAction({ deviceId: device.deviceId, testPushMessage: testMessage })
      )
    } catch (err) {
      setError(err as string)
    }
  }

  const handleBlockPushNotifications = async () => {
    try {
      await dispatch(unsubscribePushNotificationsAction(device))
    } catch (err) {
      setError(err as string)
    }
  }

  const handleAllowPushNotifications = async () => {
    try {
      await dispatch(subscribePushNotificationsAction(device))
    } catch (err) {
      setError(err as string)
    }
  }

  const handleForgetDevice = async () => {
    try {
      await dispatch(deleteDeviceAction(device.deviceId))
    } catch (err) {
      setError(err as string)
    }
  }

  return (
    <DetailsContainer>
      <DeviceNameEditor>
        <input
          type="text"
          value={newDeviceName}
          onChange={(e) => setNewDeviceName(e.target.value)}
          placeholder={t('deviceManagement.enterDeviceName')}
        />
        <Button onClick={handleSaveName}>{t('deviceManagement.save')}</Button>
      </DeviceNameEditor>
      {error && <ErrorText>{error}</ErrorText>}
      {isCurrentDevice ? (
        <>
          <Typography variant="body1">{t('deviceManagement.currentDeviceInUse')}</Typography>
          <Typography variant="body2">{pushStatusMessage}</Typography>
          {device.pushStatus === 'permission_granted' && (
            <>
              {canBlockMessages && (
                <ActionLink onClick={handleBlockPushNotifications}>
                  {t('deviceManagement.blockPushNotifications')}
                </ActionLink>
              )}
              <ActionLink onClick={handleSendTestMessage}>
                {t('deviceManagement.sendTestMessage')}
              </ActionLink>
              {device.testMessages.length > 0 && (
                <>
                  <Typography variant="subtitle2">{t('deviceManagement.testMessages')}</Typography>
                  <TestMessages testMessages={device.testMessages} />
                </>
              )}
            </>
          )}
          {isAllowPushPossibleForPushStatus(device.pushStatus) && (
            <ActionLink onClick={handleAllowPushNotifications}>
              {t('deviceManagement.allowPushNotifications')}
            </ActionLink>
          )}
        </>
      ) : (
        <>
          <Typography variant="body1">
            {t('deviceManagement.lastLogin')}: {format(new Date(device.lastLogin), 'dd.MM.yyyy')}
          </Typography>
          <Typography variant="body2">{pushStatusMessage}</Typography>
          {device.pushStatus === 'permission_granted' && (
            <ActionLink onClick={handleBlockPushNotifications}>
              {t('deviceManagement.blockPushNotifications')}
            </ActionLink>
          )}
          {isAllowPushPossibleForPushStatus(device.pushStatus) && (
            <ActionLink onClick={handleAllowPushNotifications}>
              {t('deviceManagement.allowPushNotifications')}
            </ActionLink>
          )}
          <ActionLink onClick={handleForgetDevice} disabled={isCurrentDevice}>
            {t('deviceManagement.forgetDevice')}
          </ActionLink>
        </>
      )}
    </DetailsContainer>
  )
}

export default DeviceDetails
