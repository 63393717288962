import { createAsyncThunk } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'

import config from '../../config'
import { apiDELETE, apiPOST } from '../../lib/api'
import b64 from '../../lib/b64'
import { getDeviceIdFromDb, saveDeviceIdToDB } from '../../lib/data'
import { Device, TestPushMessage } from '../../types/Input'
import { serializePushSubscription } from './utils'

const fetchDevices = (storedDeviceId?: string): Promise<Device[]> => {
  return apiPOST('devices/getOrCreate', { storedDeviceId })
}

type FetchDevicesResponse = {
  devices: Device[]
  storedDeviceId: string
}

export const fetchDevicesAction = createAsyncThunk<
  FetchDevicesResponse,
  void,
  { rejectValue: string }
>('deviceManagement/fetchDevices', async (_, { rejectWithValue }) => {
  try {
    let storedDeviceId = await getDeviceIdFromDb()
    if (!storedDeviceId) {
      storedDeviceId = uuidv4()
      await saveDeviceIdToDB(storedDeviceId)
    }

    const devices = await fetchDevices(storedDeviceId)

    return { devices, storedDeviceId }
  } catch (error: any) {
    return rejectWithValue(error.message || 'Failed to fetch devices')
  }
})

const sendSubscription = (device: Device): Promise<{ device: Device }> => {
  return apiPOST('push/register', { device })
}

export const subscribePushNotificationsAction = createAsyncThunk<
  Device,
  Device,
  { rejectValue: string }
>('deviceManagement/subscribePushNotifications', async (device, { rejectWithValue }) => {
  try {
    if (!navigator.serviceWorker) {
      throw new Error('No serviceWorker found')
    }

    const registration = await navigator.serviceWorker.ready
    if (!registration.pushManager) {
      throw new Error('No pushManager found')
    }

    let permission = Notification.permission

    if (permission === 'default') {
      permission = await Notification.requestPermission()
    }

    if (permission !== 'granted') {
      throw new Error('No permission to subscribe')
    }

    let subscription = await registration.pushManager.getSubscription()

    if (subscription === null) {
      subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: b64(config.vapid),
      })
    }

    const serializedSubscription = await serializePushSubscription(subscription)
    const updatedDevice: Device = { ...device, subscription: serializedSubscription }

    await sendSubscription(updatedDevice)

    return updatedDevice
  } catch (error: any) {
    return rejectWithValue(error.message || 'Failed to subscribe push notifications')
  }
})

const sendUnsubscription = (device: Device): Promise<{ devices: Device[] }> => {
  return apiPOST('push/unregister', { device })
}

export const unsubscribePushNotificationsAction = createAsyncThunk<
  Device,
  Device,
  { rejectValue: string }
>('deviceManagement/unsubscribePushNotifications', async (device, { rejectWithValue }) => {
  try {
    if (navigator.serviceWorker) {
      const registration = await navigator.serviceWorker.ready
      const subscription = await registration.pushManager.getSubscription()
      if (!subscription) {
        throw new Error('No subscription found')
      }

      const unsubscribedDevice = { ...device, subscription: null }
      await subscription.unsubscribe()
      await sendUnsubscription(unsubscribedDevice)

      return unsubscribedDevice
    } else {
      throw new Error('No serviceWorker found')
    }
  } catch (error: any) {
    return rejectWithValue(error.message || 'Failed to unsubscribe push notifications')
  }
})

const sendTestPushToDevice = (
  payload: DeviceTestMessagePayload
): Promise<DeviceTestMessagePayload> => {
  return apiPOST('devices/test', payload)
}

export type DeviceTestMessagePayload = {
  deviceId: string
  testPushMessage: TestPushMessage
}

export const sendTestPushMessageAction = createAsyncThunk<
  DeviceTestMessagePayload,
  DeviceTestMessagePayload,
  { rejectValue: string }
>('deviceManagement/sendTestPushMessage', async (payload, { rejectWithValue }) => {
  try {
    return await sendTestPushToDevice(payload)
  } catch (error: any) {
    return rejectWithValue(error.message || 'Failed to send test push message')
  }
})

const deleteDevice = (deviceId?: string): Promise<unknown> => apiDELETE(`devices/${deviceId}`)

export const deleteDeviceAction = createAsyncThunk<
  string, // deviceId
  string, // deviceId
  { rejectValue: string }
>('deviceManagement/deleteDevice', async (deviceId, { rejectWithValue }) => {
  try {
    await deleteDevice(deviceId)
    return deviceId
  } catch (error: any) {
    return rejectWithValue(error.message || 'Failed to delete device')
  }
})

const updateDeviceName = (device: Device): Promise<Device> => {
  return apiPOST('devices', device)
}

type DeviceNamePayload = {
  device: Device
  newName: string
}

export const updateDeviceNameAction = createAsyncThunk<
  Device,
  DeviceNamePayload,
  { rejectValue: string }
>('deviceManagement/updateDeviceName', async (payload, { rejectWithValue }) => {
  try {
    const updatedDevice: Device = {
      ...payload.device,
      deviceName: payload.newName,
    }

    return await updateDeviceName(updatedDevice)
  } catch (error: any) {
    return rejectWithValue(error.message || 'Failed to update device name')
  }
})
