import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useTypedDispatch, useTypedSelector } from '..'
import { fetchDevicesAction } from '../actions/deviceManagement'
import DeviceList from '../components/deviceManagement/DeviceList'
import ErrorText from '../components/ErrorText'
import LoadingIndicator from '../components/LoadingIndicator'
import Page from '../components/page/Page'
import {
  BackIcon,
  Container as PageContainer,
  Content,
  HeaderText,
  PageHeader,
} from '../components/page/PageComponents'
import ArrowLeft from '../icons/ArrowLeft'
import { setExpandedDeviceId } from '../reducers/deviceManagementSlice'

const DeviceManagementPage = () => {
  const dispatch = useTypedDispatch()
  const { t } = useTranslation()

  const devices = useTypedSelector((state) => state.deviceManagement.devices)
  const loading = useTypedSelector((state) => state.deviceManagement.loading)
  const error = useTypedSelector((state) => state.deviceManagement.error)
  const expandedDeviceId = useTypedSelector((state) => state.deviceManagement.expandedDeviceId)
  const currentDeviceId = useTypedSelector((state) => state.deviceManagement.currentDeviceId)

  const getDevices = useCallback(() => dispatch(fetchDevicesAction()), [dispatch])

  useEffect(() => {
    if (devices.length === 0) {
      getDevices()
    }
  }, [devices.length, dispatch, getDevices])

  const handleExpand = (deviceId: string) => {
    dispatch(setExpandedDeviceId(expandedDeviceId === deviceId ? null : deviceId))
  }

  const canBlockMessages = devices.filter((d) => d.pushStatus === 'permission_granted').length > 1

  return (
    <Page menu="options">
      <PageContainer>
        <PageHeader onClick={() => window.history.back()} height="64px">
          <BackIcon>
            <ArrowLeft iconSize="normal" />
          </BackIcon>
          <HeaderText>{t('deviceManagement.title')}</HeaderText>
        </PageHeader>
        <Content>
          <PageContainer>
            {loading ? (
              <LoadingIndicator size="normal" padded />
            ) : (
              <>
                {error && <ErrorText>{error}</ErrorText>}
                <DeviceList
                  devices={devices}
                  expandedDeviceId={expandedDeviceId}
                  handleExpand={handleExpand}
                  currentDeviceId={currentDeviceId}
                  canBlockMessages={canBlockMessages}
                />
              </>
            )}
          </PageContainer>
        </Content>
      </PageContainer>
    </Page>
  )
}

export default DeviceManagementPage
