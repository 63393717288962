import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { format } from 'date-fns'
import React from 'react'

import { Device, PushStatus } from '../../types/Input'
import DeviceDetails from './DeviceDetails'

const CardContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(1),
}))

const IconRow = styled('div')(({ theme }) => ({
  textDecoration: 'none',
  padding: theme.spacing(1),
  background:
    theme.palette.mode === 'dark'
      ? theme.palette.background.default
      : theme.palette.background.paper,
  color: theme.palette.text.primary,
  boxSizing: 'border-box',
  border: `1px solid ${theme.palette.divider}`,
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  borderBottom: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  cursor: 'pointer',
}))

const Cell = styled('div')(({ theme }) => ({
  flex: 1,
  textAlign: 'center',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  ...theme.typography.body2,
}))

const ArrowIcon = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  color: theme.palette.primary.main,
}))

const getPushStatusIcon = (status: PushStatus): React.ReactNode => {
  if (status === 'permission_granted') {
    return <CheckCircleIcon style={{ color: 'green' }} />
  }
  return <CancelIcon style={{ color: 'red' }} />
}

type DeviceCardProps = {
  device: Device
  isExpanded: boolean
  onExpand: () => void
  isCurrentDevice: boolean
  canBlockMessages: boolean
}

const DeviceCard = ({
  device,
  isExpanded,
  onExpand,
  isCurrentDevice,
  canBlockMessages,
}: DeviceCardProps) => {
  return (
    <CardContainer>
      <IconRow onClick={onExpand}>
        <Cell>{device.deviceName}</Cell>
        <Cell>{format(new Date(device.lastLogin), 'dd.MM.yyyy')}</Cell>
        <Cell>{getPushStatusIcon(device.pushStatus)}</Cell>
        <ArrowIcon>{isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</ArrowIcon>
      </IconRow>
      {isExpanded && (
        <DeviceDetails
          device={device}
          isCurrentDevice={isCurrentDevice}
          canBlockMessages={canBlockMessages}
        />
      )}
    </CardContainer>
  )
}

export default DeviceCard
