import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { TestPushMessage } from '../../types'

const TestMessagesBox = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.success.light,
  padding: theme.spacing(1),
  color: theme.palette.text.primary,
  borderRadius: theme.shape.borderRadius,
  marginTop: theme.spacing(1),
}))

const TestMessageItem = styled('div')(({ theme }) => ({
  ...theme.typography.body2,
  marginBottom: theme.spacing(0.5),
}))

type TestMessagesProps = {
  testMessages: TestPushMessage[]
}

const TestMessages = ({ testMessages }: TestMessagesProps) => {
  const { t } = useTranslation()

  return (
    <TestMessagesBox>
      {testMessages.map((msg) => (
        <TestMessageItem key={msg.messageId}>
          <Typography variant="body2">
            {t('deviceManagement.testMessageRequested')}:{' '}
            {format(new Date(msg.sentAt), 'dd.MM.yyyy HH.mm')}
          </Typography>
          {msg.receivedAt ? (
            <Typography variant="body2">
              {t('deviceManagement.testMessageReceived')}:{' '}
              {format(new Date(msg.receivedAt), 'dd.MM.yyyy HH.mm')}
            </Typography>
          ) : (
            <Typography variant="body2">{t('deviceManagement.notReceivedYet')}</Typography>
          )}
        </TestMessageItem>
      ))}
    </TestMessagesBox>
  )
}

export default TestMessages
