import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  deleteDeviceAction,
  DeviceTestMessagePayload,
  fetchDevicesAction,
  sendTestPushMessageAction,
  subscribePushNotificationsAction,
  unsubscribePushNotificationsAction,
  updateDeviceNameAction,
} from '../actions/deviceManagement'
import { Device } from '../types/Input'

interface ActionStatus {
  loading: boolean
  error: string | null
}

interface DeviceManagementState {
  devices: Device[]
  loading: boolean
  error: string | null
  expandedDeviceId: string | null
  currentDeviceId: string | null
  actions: {
    subscribePush: ActionStatus
    unsubscribePush: ActionStatus
    sendTestPushMessage: ActionStatus
    deleteDevice: ActionStatus
    updateDeviceName: ActionStatus
  }
}

const initialState: DeviceManagementState = {
  devices: [],
  loading: false,
  error: null,
  expandedDeviceId: null,
  currentDeviceId: null,
  actions: {
    subscribePush: { loading: false, error: null },
    unsubscribePush: { loading: false, error: null },
    sendTestPushMessage: { loading: false, error: null },
    deleteDevice: { loading: false, error: null },
    updateDeviceName: { loading: false, error: null },
  },
}

const deviceManagementSlice = createSlice({
  name: 'deviceManagement',
  initialState,
  reducers: {
    setExpandedDeviceId(state, action: PayloadAction<string | null>) {
      state.expandedDeviceId = action.payload
    },
    setCurrentDeviceId(state, action: PayloadAction<string | null>) {
      state.currentDeviceId = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDevicesAction.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(
        fetchDevicesAction.fulfilled,
        (state, action: PayloadAction<{ devices: Device[]; storedDeviceId: string }>) => {
          state.devices = action.payload.devices
          state.currentDeviceId = action.payload.storedDeviceId
          state.loading = false
        }
      )
      .addCase(fetchDevicesAction.rejected, (state, action) => {
        state.loading = false
        state.error = (action.payload as string) || 'Failed to fetch devices'
      })

    builder
      .addCase(subscribePushNotificationsAction.pending, (state) => {
        state.actions.subscribePush.loading = true
        state.actions.subscribePush.error = null
      })
      .addCase(subscribePushNotificationsAction.fulfilled, (state, action) => {
        state.actions.subscribePush.loading = false
        const updatedDevice = state.devices.find(
          (device) => device.deviceId === action.payload.deviceId
        )
        if (updatedDevice) {
          updatedDevice.pushStatus = 'permission_granted'
        }
      })
      .addCase(subscribePushNotificationsAction.rejected, (state, action) => {
        state.actions.subscribePush.loading = false
        state.actions.subscribePush.error =
          (action.payload as string) || 'Failed to subscribe push notifications'
      })

    builder
      .addCase(unsubscribePushNotificationsAction.pending, (state) => {
        state.actions.unsubscribePush.loading = true
        state.actions.unsubscribePush.error = null
      })
      .addCase(unsubscribePushNotificationsAction.fulfilled, (state, action) => {
        state.actions.unsubscribePush.loading = false
        const updatedDevice = state.devices.find(
          (device) => device.deviceId === action.payload.deviceId
        )
        if (updatedDevice) {
          updatedDevice.pushStatus = 'blocked_by_user'
        }
      })
      .addCase(unsubscribePushNotificationsAction.rejected, (state, action) => {
        state.actions.unsubscribePush.loading = false
        state.actions.unsubscribePush.error =
          (action.payload as string) || 'Failed to unsubscribe push notifications'
      })

    builder
      .addCase(sendTestPushMessageAction.pending, (state) => {
        state.actions.sendTestPushMessage.loading = true
        state.actions.sendTestPushMessage.error = null
      })
      .addCase(
        sendTestPushMessageAction.fulfilled,
        (state, action: PayloadAction<DeviceTestMessagePayload>) => {
          state.actions.sendTestPushMessage.loading = false
          const device = state.devices.find((d) => d.deviceId === action.payload.deviceId)
          if (device) {
            device.testMessages.push(action.payload.testPushMessage)
          }
        }
      )
      .addCase(sendTestPushMessageAction.rejected, (state, action) => {
        state.actions.sendTestPushMessage.loading = false
        state.actions.sendTestPushMessage.error =
          action.payload || 'Failed to send test push message'
      })

    builder
      .addCase(deleteDeviceAction.pending, (state) => {
        state.actions.deleteDevice.loading = true
        state.actions.deleteDevice.error = null
      })
      .addCase(deleteDeviceAction.fulfilled, (state, action: PayloadAction<string>) => {
        state.actions.deleteDevice.loading = false
        state.devices = state.devices.filter((d) => d.deviceId !== action.payload)
      })
      .addCase(deleteDeviceAction.rejected, (state, action) => {
        state.actions.deleteDevice.loading = false
        state.actions.deleteDevice.error = action.payload || 'Failed to delete device'
      })

    builder
      .addCase(updateDeviceNameAction.pending, (state) => {
        state.actions.updateDeviceName.loading = true
        state.actions.updateDeviceName.error = null
      })
      .addCase(updateDeviceNameAction.fulfilled, (state, action: PayloadAction<Device>) => {
        state.actions.updateDeviceName.loading = false
        const index = state.devices.findIndex((d) => d.deviceId === action.payload.deviceId)
        if (index !== -1) {
          state.devices[index] = action.payload
        }
      })
      .addCase(updateDeviceNameAction.rejected, (state, action) => {
        state.actions.updateDeviceName.loading = false
        state.actions.updateDeviceName.error =
          (action.payload as string) || 'Failed to update device name'
      })
  },
})

export const { setExpandedDeviceId, setCurrentDeviceId } = deviceManagementSlice.actions

export default deviceManagementSlice.reducer
