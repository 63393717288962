import axios, { AxiosRequestConfig } from 'axios'

import { logout } from '../actions/api'
import config from '../config'
import { TASK_RETRY_COOLDOWN_MS, TASK_RETRY_COUNT } from '../constants'
import { store } from '../index'
import { Action } from '../types'
import type { Session } from '../types/Input'
import { rethrowNonRetryable, returnNonRetryable } from './errors'
import type { Task } from './executor'
import Executor, { createKeyedTask } from './executor'
import { error } from './logger'
import { hasParentPage, reloadParentPage } from './pwa'

type AxiosConfig = {
  headers: Record<string, string | undefined>
  timeout: number
}

const executor = new Executor()
const instance = axios.create({
  baseURL: config.apiBaseUrl,
})
instance.defaults.headers.post['Content-Type'] = 'application/json'
instance.interceptors.response.use(
  (response) => {
    return response.data
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // TODO: type all "as unknown as <X>" properly
      store.dispatch(logout as unknown as Action)
    }
    return Promise.reject(error)
  }
)

const axiosConfig: AxiosConfig = {
  headers: { sessionId: 'null', Authorization: undefined },
  timeout: 70000,
}

export const apiGET = async <T>(url: string): Promise<T> => instance.get(url, axiosConfig)

export const apiPOST = <T>(
  url: string,
  data: unknown,
  customAxiosConfig?: AxiosRequestConfig
): Promise<T> => instance.post(url, data, { ...axiosConfig, ...customAxiosConfig })

export const apiDELETE = async (url: string): Promise<unknown> => instance.delete(url, axiosConfig)

// eSäLLi does not seem to be capable to handle
// multiple concurrent requests, so API calls will
// be done in sequence and retried with static intervals
function retriedSequentialTask<T>(key: string, fn: () => Promise<T>): Promise<T> {
  return new Promise((resolve, reject) => {
    const t: Task = createKeyedTask(
      key,
      () => fn().then(resolve),
      undefined,
      TASK_RETRY_COUNT,
      TASK_RETRY_COOLDOWN_MS,
      reject
    )
    executor.enqueue(t)
    executor.execute()
  })
}

// Executes GET request task, but knows which
// status codes should not be retried
export function getWithRetry<T>(url: string, optionalKey?: string): Promise<T> {
  return retriedSequentialTask(`GET:${url}:${optionalKey || ''}`, () =>
    apiGET<T>(url).catch(returnNonRetryable)
  ).then(rethrowNonRetryable)
}

export function postWithRetry<T>(
  url: string,
  data?: unknown,
  axiosConfig?: AxiosRequestConfig
): Promise<T> {
  return retriedSequentialTask(`POST:${url}:${JSON.stringify(data)}`, () => {
    const res = apiPOST(url, data, axiosConfig).catch(returnNonRetryable)
    return res
  }).then(rethrowNonRetryable)
}

export function deleteWithRetry<T>(url: string): Promise<T> {
  return retriedSequentialTask(`DELETE:${url}`, () =>
    apiDELETE(url).catch(returnNonRetryable)
  ).then(rethrowNonRetryable)
}

export const clearSession = () => {
  axiosConfig.headers.Authorization = undefined
}

export const useSession = (session: Session): Session => {
  // This window is on top of the actual PWA,
  // refresh parent window and close this one
  try {
    if (hasParentPage()) {
      reloadParentPage()
      window.close()
    }
  } catch (e) {
    error('Error while reloading underlying PWA page', e)
  }

  axiosConfig.headers.Authorization = `Bearer ${session.token}`

  return session
}

export const setSessionId = (id: string) => {
  //Session id used in axios config is hashed for security reasons
  let hash = 0,
    i,
    chr
  if (id.length === 0) return hash

  for (i = 0; i < id.length; i++) {
    chr = id.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0
  }
  axiosConfig.headers.sessionId = hash.toString()
}
